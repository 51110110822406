import 'scss/pages/about-us.scss';
import React from 'react';
import { Trans } from '@lingui/macro';
import Layout from 'components/Layout';
import Seo from 'components/SEO';
import Image from 'components/atoms/Image/Image';
import {Link} from 'gatsby';
import {List, ListItem} from 'components/atoms/List/List';
import {careerContactFormProps, Perks} from "../../page-components/careers/common";

const SeniorFrontendDeveloper = () => {
  return (
      <Layout contactFormProps={careerContactFormProps} >
        <Seo title="Senior Frontend Developer at YanchWare"/>
        <section className="hero-wrapper">
          <div className="hero">
            <div className="hero__content">
              <h1 className="hero__title">
                <Trans>Senior Frontend Developer @ YanchWare</Trans>
              </h1>
              <p className="hero__text">
                <Trans>
                  Join our Avanguardia team as a Senior Frontend Developer and play a pivotal role in shaping the user
                  experience of our flagship products, Fractal and Ocelot.
                </Trans>
              </p>
              <p className="hero__text">
                <Trans>
                  In this role, you will design and implement intuitive and straightforward interfaces that simplify
                  complex processes, enhancing user engagement and satisfaction. Your efforts will contribute to
                  making our innovative cloud solutions more accessible and effective for our users.
                </Trans>
              </p>
              <Link to="/contact-us" className="bg-green-900 w-[190px] mt-m py-xs px-m rounded-[10px] flex justify-between">
                <span className="text-transparent bg-clip-text bg-hero-text">
                  <Trans>Apply now</Trans>
                </span>
                <Image name="arrow-icon" className="fill-icon mr-s" />
              </Link>
            </div>
            <Image name="careers/senior-frontend-developer" className="hero__image"/>
          </div>
        </section>

        <section className="container tac">
          <h2 className="color-primary">
            <Trans>About the role</Trans>
          </h2>

          <div className="content-blocks content-blocks--reverse">
            <div className="content-block">
              <Image
                  name="about-us-page/customer-success"
                  className="content-block__image"
              />
              <div className="content-block__info">
                <h2>
                  <Trans>Responsibilities:</Trans>
                </h2>
                <p>
                  <Trans>
                    As a Senior Frontend Developer at YanchWare, you will be at the heart of our product development,
                    ensuring that our innovative cloud solutions meet the highest standards of usability and excellence.
                    Here's how you will contribute:
                  </Trans>
                </p>
                <p>
                  <List>
                    <ListItem>
                      <Trans>
                        Design and implement user interfaces that transform the interaction with our products into
                        intuitive and delightful experiences.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Collaborate closely with UX designers, product managers, and back-end engineers to ensure that
                        the front-end contributes effectively to the overall functionality and quality of our products.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Ensure that the front-end interfaces you develop are responsive, accessible, and performant
                        across all devices.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Lead discussions on front-end technologies and strategies that can help enhance the visual and
                        interactive elements of our cloud solutions.
                      </Trans>
                    </ListItem>
                  </List>
                </p>
              </div>
            </div>
            <div className="content-block">
              <Image
                  name="about-us-page/about-us-hero"
                  className="content-block__image"
              />
              <div className="content-block__info">

                <h2>
                  <Trans>Success Criteria:</Trans>
                </h2>
                <p>
                <Trans>
                    Your success as a Senior Frontend Developer will be measured by your ability to deliver results that
                    not only meet but exceed our standards of cloud product excellence. Key performance indicators
                    include:
                  </Trans>
                </p>
                <p>
                  <List>
                    <ListItem>
                      <Trans>
                        Deliver user interfaces that are not only visually appealing but also highly functional,
                        contributing to an overall seamless user experience.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Enhance user satisfaction and engagement through effective design and implementation of
                        front-end features.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Implement front-end solutions that are responsive, fast, and aligned with our core product
                        objectives.
                      </Trans>
                    </ListItem>
                  </List>
                </p>
              </div>
            </div>
            <div className="content-block">
              <Image
                  name="careers/whoareu"
                  className="content-block__image"
              />
              <div className="content-block__info">
                <h2>
                  <Trans>Who are you:</Trans>
                </h2>
                <p>
                <Trans>
                  You are a forward-thinking Frontend Developer who thrives on innovation and is passionate about
                  crafting high-quality, scalable user interfaces:
                  </Trans>
                </p>
                <p>
                  <List>
                    <ListItem>
                      <Trans>
                        Tech Savvy: Proficient in our core frontend tech stack, including TypeScript, React, Redux,
                        TailwindCSS, and Cypress, you have a knack for building intuitive and dynamic user interfaces
                        that enhance user engagement.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Process Oriented: Experienced with CI/CD pipelines using tools like GitHub, GitHub Actions,
                        Google Cloud Build, and Docker, you understand the importance of automation and efficient
                        development workflows in delivering high-quality software.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Methodically Minded: Skilled in modern development methodologies such as Kanban for agile
                        workflow management, Micro-frontends for decoupled and scalable UI architectures, and Flux for
                        predictable state management across complex applications. You excel at developing reusable
                        components that streamline development and maintain consistency across large applications.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Team Collaborator: A team player who enjoys sharing knowledge and learning from peers, you excel
                        in environments that value open communication and collaborative problem-solving.
                      </Trans>
                    </ListItem>
                  </List>
                </p>
              </div>
            </div>
          </div>
          <Perks/>
        </section>
      </Layout>
  );
};

export default SeniorFrontendDeveloper;
